<template>
  <div class="bg-white">

    <header class="absolute inset-x-0 top-20 z-50">
      <div class="flex justify-center items-center">
        <a href="#" class="-m-1.5 p-1.5">
          <span class="sr-only">Your Company</span>
          <img class="h-24 w-auto" src="@/assets/logo.png" alt="" />
        </a>
      </div>
    </header>

    <div class=" isolate px-6 lg:px-8">
      <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
           aria-hidden="true">
        <div
            class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
      </div>

      <div class="mx-auto text-start max-w-5xl py-32 sm:py-48 lg:py-40 relative">
        <div class="text-center">
          <h1 class="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Bienvenue à Myth Jeux</h1>
          <p class="mt-4 text-lg leading-4 mx-10  lg:px-40 text-gray-600">Myth Jeux is the newest studio in Belgium, we just arrived
            bringing 8 years of experience from Brazil to start a new adventure here.</p>
        </div>
        <div class="hidden pt-10 py-2  sm:flex sm:justify-center">
          <div
              class="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            You may expect from us:
          </div>
        </div>
        <div class="grid lg:grid-cols-2 grid-cols-1 mx-5 justify-center items-center ">
          <p class="mt-4 text-lg leading-4 mx-10  text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Many fun games
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Participating in all sorts of events
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Gathering all the other studios to collaborate and grow together!
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Partnership for co-production, Porting and Publishing Games
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Localization to Brasil
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Gamification projects
          </p>
          <p class="mt-4 text-lg leading-4 mx-10 text-gray-600">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="w-6 h-6 inline-block text-blue-500">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"/>
            </svg>
            - Gamification Courses
          </p>
        </div>
      </div>
      <div
          class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true">
        <div
            class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)"/>
      </div>
    </div>
  </div>

  <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
    <div class="absolute inset-0 -z-50 overflow-hidden">
      <svg class="absolute opacity-5 left-[max(10%,30rem)] top-0 h-[64rem] w-[140rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]" aria-hidden="true">
        <defs>
          <pattern id="e813992c-7d03-4cc4-a2bd-151760b470a0" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
          <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
        </svg>
        <rect width="100%" height="100%" stroke-width="0" fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
      </svg>
    </div>
    <section class=" relative">
      <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
        <div class="flex justify-center xl:w-1/2">
          <img class="h-96 w-80 sm:w-[28rem] sm:h-[35rem] flex-shrink-0 object-cover rounded-3xl" src="@/assets/imagem.png" alt="">
        </div>

        <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
          <h2 class="text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
            About Me
          </h2>

          <p class="block max-w-2xl text-start mt-4 text-gray-500 dark:text-gray-300">I am Rafael Lontra, I came from Brazil to study a Master Course in Video Games at HEAJ in Namur, and also to start a Game Dev Studio in Wallonia

            I am gratuated first as a music teacher, than a second one in Digital Games and a Post Gratuation also in Digital Games

            My goal is to collaborate with all the Belgian Studios, from all regions, and also with our Brazilian colleagues.

            I am also one of the associates at Mito Games in Brazil and the idea of Myth Jeux is to connect the best of the Belgium and Brazil

            Feel free to get in touch if you are interested in getting to know more, propose any sort of partnership or just connect.</p>
        </div>
      </div>
    </section>
  </div>

  <section class="bg-gray-900">
    <div class="container px-6  py-10 mx-auto">
      <h1 class="mt-2 text-3xl text-white font-bold tracking-tight sm:text-4xl">Portfolio
      </h1>

      <section class="mt-8 space-y-8 lg:mt-12">

        <TheElementsXsolla></TheElementsXsolla>

        <section class="lg:flex bg-gray-800 lg:py-0 py-10 rounded-3xl lg:items-center flex-row-reverse">
          <div class="lg:w-1/2 ">
            <p class="text-lg tracking-wider uppercase text-blue-400 ">Game</p>
            <h2 class="mt-2 text-2xl font-semibold pb-4 capitalize text-white">Pop Some Eyes</h2>
            <a target="_blank" href="https://www.youtube.com/watch?v=K91kfNGifbg&t=1s" class="text-sm font-semibold leading-6 text-white">Watch Trailer <span aria-hidden="true">→</span></a>
          </div>

          <div class="px-6">
            <PopSomeEyesXsolla></PopSomeEyesXsolla>
          </div>

          <div class="mt-4 lg:w-1/2 lg:mt-0">
            <img class="object-cover  h-64 rounded-lg md:h-72"
                 src="@/assets/game2.jpeg"
                 alt="">
          </div>
        </section>

        <section class="lg:flex  lg:py-0 py-10 lg:items-center">
          <div class="lg:w-1/2 ">
            <p class="text-lg tracking-wider uppercase text-blue-400 ">Game</p>
            <h2 class="mt-2 text-2xl font-semibold  pb-4 capitalize text-white">Box Dungeons</h2>
            <a target="_blank" href="https://cdn.cloudflare.steamstatic.com/steam/apps/256948990/movie480_vp9.webm?t=1685398862" class="text-sm font-semibold leading-6 text-white">Watch Trailer <span aria-hidden="true">→</span></a>
          </div>

          <div class="px-6">
            <BoxDungeonsXsolla></BoxDungeonsXsolla>
          </div>

          <div class="mt-4 lg:w-1/2 lg:mt-0">
            <img class="object-cover  h-64 rounded-lg md:h-72"
                 src="@/assets/game3.jpeg"
                 alt="">
          </div>
        </section>

        <section class="mt-8 space-y-8 lg:mt-12">

          <section class="lg:flex bg-gray-800 lg:py-0 py-10 rounded-3xl lg:items-center flex-row-reverse">
            <div class="lg:w-1/2 ">
              <p class="text-lg tracking-wider uppercase text-blue-400 ">Game</p>
              <h2 class="mt-2 text-2xl font-semibold pb-4 capitalize text-white">Tupi: The Legend of Arariboia</h2>
              <a target="_blank" href="https://www.youtube.com/watch?v=ObBGs5b_hrI" class="text-sm font-semibold leading-6 text-white">Watch Trailer <span aria-hidden="true">→</span></a>
            </div>

            <div class="mt-4 lg:w-1/2 lg:mt-0">
              <img class="object-cover  h-64 rounded-lg md:h-72"
                   src="@/assets/arariboia.png"
                   alt="">
            </div>
          </section>
        </section>
      </section>
    </div>
  </section>

<Footer/>
</template>

<script>

import BoxDungeonsXsolla from "@/components/BoxDungeonsXsolla.vue";
import PopSomeEyesXsolla from "@/components/PopSomeEyesXsolla.vue";
import TheElementsXsolla from "@/components/TheElementsXsolla.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'PopSomeEyes',
  components: {
    Footer,
    BoxDungeonsXsolla,
    PopSomeEyesXsolla,
    TheElementsXsolla,

  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
